<template>
    <a-form :labelCol="labelCol" :wrapperCol="wrapperCol" :form="form" style="max-width: 500px; margin: 40px auto 0;">
      <a-form-item label="登录账号">
        <a-input
          type="text"
          v-decorator="['username', {initialValue: stepData.username, rules: [{required: true, message: '请输入登录账号'}]}]"
        />
      </a-form-item>
      <a-form-item label="登录密码">
        <a-input-password
          v-decorator="['password', {initialValue: stepData.password, rules: [{required: true, message: '请输入登录密码'}]}]"
        />
      </a-form-item>
      <a-form-item label="账号类型">
        <a-select v-decorator="['role_id', {initialValue: stepData.role_id, rules: [{required: true, message: '请输入登录密码'}]}]" :allowClear="true">
          <a-select-option v-if="userInfo.role_id == 0" :value="1">公司</a-select-option>
          <a-select-option v-if="userInfo.role_id == 0" :value="2">服务商</a-select-option>
          <a-select-option :value="4">分包商</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="验证码">
        <a-input
          v-decorator="['code', {initialValue: stepData.code, rules: [{required: true, message: '请输入登录密码'}]}]"
        >
        <img slot="addonAfter" :src="codeUrl" alt="code" @click="resetCode" />
        </a-input>
      </a-form-item>
      <a-form-item style="margin-top:24px">
        <a-button size="large" type="primary" @click="nextStep">下一步</a-button>
      </a-form-item>
    </a-form>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Step1',
  data () {
    return {
      stepData: {
        username: '',
        password: '',
        role_id: '',
        code: ''
      },
      codeUrl: '/admin/code',
      labelCol: { lg: { span: 5 }, sm: { span: 5 } },
      wrapperCol: { lg: { span: 19 }, sm: { span: 19 } },
      form: this.$form.createForm(this)
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted () {
    this.stepData = {
      username: this.formData.username || '',
      password: this.formData.password || '',
      role_id: this.formData.role_id || '',
      code: this.formData.code || ''
    }
  },
  methods: {
    resetCode() {
      this.codeUrl = `admin/code?${Math.random()}`
    },
    nextStep () {
      
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$emit('nextStep', values)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
