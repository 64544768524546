<template>
  <div class="container">
    <a-page-header title="添加" :backIcon="false"></a-page-header>
    <div class="wrapper">
      <a-card :bordered="false">
        <a-steps class="steps" :current="currentTab">
          <a-step title="账号信息" />
          <template v-if="formData.role_id && formData.role_id != 4">
            <a-step :title="formData.role_id == 1 ? '企业基本信息' : '服务商基本信息'" />
            <a-step :title="formData.role_id == 1 ? '企业联系人信息' : '服务信息'" />
            <a-step title="资质协议" />
          </template>
          <a-step v-if="formData.role_id == 4" title="基本信息" />
        </a-steps>
        <div class="content">
          <step1 :formData="formData" v-if="currentTab === 0" @nextStep="nextStep"/>
          <template v-if="formData.role_id && formData.role_id != 4">
            <step2 :formData="formData" v-if="currentTab === 1" @nextStep="nextStep" @prevStep="prevStep"/>
            <step3 :formData="formData" v-if="currentTab === 2 && formData.role_id == 1" @nextStep="nextStep" @prevStep="prevStep"/>
            <step5 :formData="formData" v-if="currentTab === 2 && formData.role_id == 2" @nextStep="nextStep" @prevStep="prevStep"/>
            <step4 :formData="formData" v-if="currentTab === 3" @prevStep="prevStep" @finish="finish"/>
          </template>
          <step6 :formData="formData" v-if="formData.role_id == 4 && currentTab === 1" @prevStep="prevStep" @finish="finish"/>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>

import { createUser } from '@/utils/api'
import Step1 from '../components/Step1'
import Step2 from '../components/Step2'
import Step3 from '../components/Step3'
import Step4 from '../components/Step4'
import Step5 from '../components/Step5'
import Step6 from '../components/Step6'
export default {
  name: 'AddUser',
  data() {
    return {
      currentTab: 0,
      formData: {}
    }
  },
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
  },
  methods: {
    nextStep (data) {
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const element = data[key]
          this.formData[key] = element
        }
      }
      this.currentTab += 1
    },
    prevStep () {
      if (this.currentTab > 0) {
        this.currentTab -= 1
      }
    },
    finish (data) {
      if (data['yyzz']) {
        this.formData['yyzz'] = data['yyzz']
      } else {
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const element = data[key]
            this.formData[key] = element
          }
        }
      }
      const hide = this.$message.loading({ content: '生成中...', key: 'register' })
      createUser(this.formData).then(res => {
        setTimeout(hide, 500)
        if (res.error == 0) {
          this.$success({
            title: '提示',
            content: '添加成功',
            okText: '确定',
            onOk() {
              window.location.reload()
            }
          })
        } else {
          this.$error({
            title: '提示',
            content: res.info,
            okText: '确定'
          })
        }
      }).catch(() => {
        this.$message.error('注册失败，请重试！', 2)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 24px 24px;
}
</style>
